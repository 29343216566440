import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../axiosInstance'; 
import { Container, Row, Col, Table, Button, Modal, Form, InputGroup } from 'react-bootstrap';


function ListContent() {
  const { expertiseId } = useParams(); // Assuming you're passing an 'expertiseId' through the route
  const [contents, setContents] = useState([]);
  const initialContentState = {
    item: '',
    description: '',
    amount_ask: '0.00',
    amount_buy: '0.00',
    date_buy: '',
    amount_propose: '0.00',
    total_content: '',
  };
  const [newContent, setNewContent] = useState(initialContentState);
  const [totalContent, setTotalContent] = useState({ total_ask: 0, total: 0 });
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [editingContent, setEditingContent] = useState(null); // Contenu actuellement en cours de modification
  const [showEditModal, setShowEditModal] = useState(false); // Pour afficher/masquer le modal de modification
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [contentToDelete, setContentToDelete] = useState(null);
  const [showDetailsModal, setShowDetailsModal] = useState(false);


  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewContent((prevContent) => ({
      ...prevContent,
      [name]: value
    }));
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditingContent((prevContent) => ({
      ...prevContent,
      [name]: value
    }));
  };
  
  const fetchContents = async () => {
    try {
      const response = await axiosInstance.get(`/contents/?expertise_id=${expertiseId}`);
      const data = response.data;
      setContents(data.results || data)

    } catch (error) {
      console.error("Error fetching contents: ", error);
    }
  };

  const fetchTotalContent = async () => {
    try {
      const response = await axiosInstance.get(`/totalcontent/${expertiseId}/`);
      setTotalContent(response.data); // Supposons que la réponse est directement l'objet désiré
      setIsLoading(false);
    } catch (error) {
      console.error("Erreur lors de la récupération du contenu total :", error);
      setIsLoading(false);
    }
  };

  
  useEffect(() => {
    if (expertiseId) {
      fetchTotalContent();
      fetchContents();
    }
  }, [expertiseId]);
  

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formattedData = {
      ...newContent,
      date_buy: newContent.date_buy ? new Date(newContent.date_buy).toISOString().split('T')[0] : null,
      total_content: totalContent.id
  };
    try {
      await axiosInstance.post(`/contents/?expertise_id=${expertiseId}`, formattedData);
      handleCloseModal();
      fetchContents();
      fetchTotalContent();
      setNewContent(initialContentState);
    } catch (error) {
      console.error("Error adding new content: ", error);
    }
  };

  const handleEdit = (content) => {
    const contentToEdit = {
      ...content,
      description: content.description || '', // Remplace null par une chaîne vide
      date_buy: content.date_buy || '',
      // Assurez-vous de faire cela pour toutes les propriétés susceptibles d'être null
    };
    setEditingContent(contentToEdit);
    setShowEditModal(true);
  };
  

  
  const handleEditSubmit = async (event) => {
    event.preventDefault();
    const formattedData = {
      ...editingContent,
      date_buy: editingContent.date_buy ? new Date(editingContent.date_buy).toISOString().split('T')[0] : null,
      // Assurez-vous que total_content est correctement défini, si nécessaire
    };
    try {
      await axiosInstance.put(`/contents/${editingContent.id}/`, formattedData); // Utilisez PUT ou PATCH selon votre API
      setShowEditModal(false); // Fermez le modal de modification
      setEditingContent(null); // Réinitialisez l'état d'édition
      fetchContents();
      fetchTotalContent();
    } catch (error) {
      console.error("Error updating content: ", error);
    }
  };
  
  const handleDelete = async () => {
    if (contentToDelete) {
      try {
        await axiosInstance.delete(`/contents/${contentToDelete.id}/`);
        const newContents = contents.filter((content) => content.id !== contentToDelete.id);
        setContents(newContents);
        setShowDeleteModal(false);
        setContentToDelete(null);
        fetchTotalContent();
      } catch (error) {
        console.error("Error deleting content: ", error);
      }
    }
  };
  
  
  
  if (isLoading) {
    return <div>Chargement...</div>;
  }
  return (
    <Container fluid>
      <Row>
        <Col>
          <h2 className="expertise-title">Liste du contenu</h2>
          <Button variant="primary" onClick={handleShowModal}>
            Add New Content
          </Button>
          <Button variant="info" onClick={() => setShowDetailsModal(true)}>View Detailed Contents</Button>

          <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
              <Modal.Title>Add New Content</Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleSubmit}>
              <Modal.Body>
                {/* Ajoutez vos champs de formulaire ici */}
                <Form.Group>
                  <Form.Label>Item</Form.Label>
                  <Form.Control type="text" name="item" value={newContent.item} onChange={handleChange}/>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Description</Form.Label>
                  <Form.Control as="textarea" rows={3} name="description" value={newContent.description} onChange={handleChange}/>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Montant réclamé</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>€</InputGroup.Text>
                    <Form.Control type="number" step="0.01" name="amount_ask" value={newContent.amount_ask} onChange={handleChange}/>
                  </InputGroup>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Montant acheté</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>€</InputGroup.Text>
                    <Form.Control type="number" step="0.01" name="amount_buy" value={newContent.amount_buy} onChange={handleChange}/>
                  </InputGroup>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Date d'achat</Form.Label>
                  <Form.Control type="date" name="date_buy" value={newContent.date_buy} onChange={handleChange}/>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Montant proposé</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>€</InputGroup.Text>
                    <Form.Control type="number" step="0.01" name="amount_propose" value={newContent.amount_propose} onChange={handleChange}/>
                  </InputGroup>
                </Form.Group>
                {/* Ajoutez d'autres champs de formulaire selon votre modèle */}
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                  Cancel
                </Button>
                <Button variant="primary" type="submit">
                  Save Content
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
          <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Modifier un item</Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleEditSubmit}>
              <Modal.Body>
                <Form.Group>
                  <Form.Label>Item</Form.Label>
                  <Form.Control type="text" name="item" value={editingContent ? editingContent.item : ''} onChange={handleEditChange}/>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Description</Form.Label>
                  <Form.Control as="textarea" rows={3} name="description" value={editingContent ? editingContent.description : ''} onChange={handleEditChange}/>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Montant réclamé</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>€</InputGroup.Text>
                    <Form.Control type="number" step="0.01" name="amount_ask" value={editingContent ? editingContent.amount_ask : ''} onChange={handleEditChange}/>
                  </InputGroup>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Montant acheté</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>€</InputGroup.Text>
                    <Form.Control type="number" step="0.01" name="amount_buy" value={editingContent ? editingContent.amount_buy : ''} onChange={handleEditChange}/>
                  </InputGroup>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Date d'achat</Form.Label>
                  <Form.Control type="date" name="date_buy" value={editingContent ? editingContent.date_buy : ''} onChange={handleEditChange}/>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Montant proposé</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>€</InputGroup.Text>
                    <Form.Control type="number" step="0.01" name="amount_propose" value={editingContent ? editingContent.amount_propose : ''} onChange={handleEditChange}/>
                  </InputGroup>
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowEditModal(false)}>
                  Cancel
                </Button>
                <Button variant="primary" type="submit">
                  Save Changes
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
          <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Effacer l'objet?</Modal.Title>
            </Modal.Header>
            <Modal.Body>Etes-vous sur de vouloir effacer la ligne?</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                Cancel
              </Button>
              <Button variant="danger" onClick={() => handleDelete()}>
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal show={showDetailsModal} onHide={() => setShowDetailsModal(false)} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>Detailed Contents</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Objets</th>
                    <th>Montant réclamé</th>
                    <th>Montant acheté</th>
                    <th>Date d'achat</th>
                    <th>Montant retenu</th>
                  </tr>
                </thead>
                <tbody>
                  {contents.map((content, index) => (
                    <tr key={index}>
                      <td>{content.item}</td>
                      <td>{content.amount_ask}€</td>
                      <td>{content.amount_buy}€</td>
                      <td>{new Date(content.date_buy).toLocaleDateString()}</td>
                      <td>{content.amount_propose}€</td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td><b>Total</b></td>
                    <td>{totalContent.total_ask}€</td>
                    <td></td>
                    <td></td>
                    <td>{totalContent.total}€</td>
                  </tr>
                </tfoot>
              </Table>
              <h5>Remarque</h5>
              {contents.map((content, index) => (
                <Row key={index}>
                  <Col>
                    <p>
                      <b>{content.item}</b>: {content.description}
                    </p>

                  </Col>
                </Row>
                  ))}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowDetailsModal(false)}>Close</Button>
            </Modal.Footer>
          </Modal>

          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Totaux</th>
                <th>{totalContent.total_ask} €</th>
                <th></th>
                <th></th>
                <th>{totalContent.total} €</th>
              </tr>
              <tr>
                <th>Item</th>
                <th>Montant réclamé</th>
                <th>Montant acheté</th>
                <th>Date d'achat</th>
                <th>Montant retenu</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
            {contents.map((content) => (
              <tr key={`content_${content.id}`}>
                <td>{content.item}</td>
                <td>{content.amount_ask} €</td>
                <td>{content.amount_buy} €</td>
                <td>{content.date_buy}</td>
                <td>{content.amount_propose} €</td>
                <td>
                  <Button variant="primary" onClick={() => handleEdit(content)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5L13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5H5h.5v.5v.5v.5h.293l6.5-6.5zm-9.761 5.175l-.106.106-1.528 3.821 3.821-1.528.106-.106L3.707 10 2.032 10.675zm-.032 3.282l-.394.986.986-.394 2.598-2.598-.978-.978-2.212 2.984z"/>
                  </svg>
                  </Button>
                  <Button variant="danger" onClick={() => { setContentToDelete(content); setShowDeleteModal(true); }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" viewBox="0 0 16 16">
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                    <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                  </svg>
                  </Button>
                </td>
              </tr>
            ))}
            </tbody>

          </Table>
        </Col>
      </Row>
    </Container>
  );
}

export default ListContent;
