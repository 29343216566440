import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Form, Button, Alert } from 'react-bootstrap';
import axiosInstance from '../../axiosInstance'; 

function EditClaims() {
    const { expertiseId } = useParams();
    const [claim, setClaim] = useState({
        declaration: '',
        reference: '',
        dateClaims: '',
        adress: '',
        guarantees_ids: [],
    });
    const [guarantees, setGuarantees] = useState([]);
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');


    // Charger les détails de la réclamation
    useEffect(() => {
        axiosInstance.get(`/claims/${expertiseId}/`)
            .then(response => {
                const { data } = response;
                setClaim({
                    ...data,
                    dateClaims: data.dateClaims ? data.dateClaims.split('T')[0] : '',
                    guarantees_ids: data.guarantees ? data.guarantees.map(g => g.id) : [],
                });                
            })
            .catch(err => {
                console.error("Erreur lors du chargement de la réclamation", err);
                setError('Erreur lors du chargement de la réclamation.');
            });

        // Charger la liste des garanties disponibles
        axiosInstance.get(`/guarantee/`)
        .then(response => {
            const data = response.data;
            // Si votre API renvoie les garanties sous un champ spécifique (comme `results`), ajustez ici
            setGuarantees(data.results || data);
        })
        .catch(err => {
            console.error("Erreur lors du chargement des garanties", err);
            setError('Erreur lors du chargement des garanties.');
        });
        }, [expertiseId]);

    const handleInputChange = (event) => {
        const { name, value, options } = event.target;
        if (options) {
            const values = Array.from(options).filter(o => o.selected).map(o => parseInt(o.value, 10));
            setClaim({ ...claim, [name]: values });
        } else {
            setClaim({ ...claim, [name]: value });
        }
    };

    const handleCheckboxChange = (event) => {
        const { value, checked } = event.target;
        const valueAsNumber = parseInt(value, 10);
    
        setClaim((prevState) => {
            // Si la case est cochée, ajoutez l'ID à la liste; sinon, retirez-le.
            const newGuaranteesIds = checked
                ? [...prevState.guarantees_ids, valueAsNumber]
                : prevState.guarantees_ids.filter((id) => id !== valueAsNumber);
    
            return { ...prevState, guarantees_ids: newGuaranteesIds };
        });
    };
    

    const handleSubmit = (event) => {
        event.preventDefault();
        const formattedData = {
            ...claim,
            dateClaims: claim.dateClaims ? new Date(claim.dateClaims).toISOString().split('T')[0] : null,
        };
    
        axiosInstance.put(`/claims/${expertiseId}/`, formattedData)
            .then(response => {
                // Gestion de la réussite
                console.log("Réclamation mise à jour avec succès", response.data);
                // Afficher un message de succès
                setSuccessMessage('Les champs ont bien été modifiés.');
                // Réinitialiser l'alerte après un certain temps si nécessaire
                setTimeout(() => setSuccessMessage(''), 3000); // Masquer l'alerte après 3 secondes
            })
            .catch(error => {
                console.error("Erreur lors de la mise à jour de la réclamation", error);
                setError('Erreur lors de la mise à jour de la réclamation.');
            });
    };
    
    

    return (
        <Container fluid>
            <h2 className="expertise-title">Modifier les données du sinistre</h2>
            {error && <Alert variant="danger">{error}</Alert>}
            {successMessage && <Alert variant="success">{successMessage}</Alert>}
            <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                    <Form.Label>Référence de la compagnie</Form.Label>
                    <Form.Control type="text" name="reference" value={claim.reference || ''} onChange={handleInputChange} />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Date du sinistre</Form.Label>
                    <Form.Control type="date" name="dateClaims" value={claim.dateClaims || ''} onChange={handleInputChange} />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Adresse du sinistre</Form.Label>
                    <Form.Control type="text" name="adress" value={claim.adress || ''} onChange={handleInputChange} />
                </Form.Group>
                {/* <Form.Group className="mb-3">
                    <Form.Label>Déclaration</Form.Label>
                    <Form.Control type="text" name="declaration" value={claim.declaration || ''} onChange={handleInputChange} />
                </Form.Group> */}
                <Form.Group className="mb-3">
                    <Form.Label>Garanties</Form.Label>
                    <div>
                        {guarantees.map((guarantee) => (
                            <Form.Check 
                                key={guarantee.id}
                                type="checkbox"
                                id={`guarantee-${guarantee.id}`}
                                label={guarantee.name}
                                value={guarantee.id}
                                checked={claim.guarantees_ids.includes(guarantee.id)}
                                onChange={handleCheckboxChange}
                            />
                        ))}
                    </div>
                </Form.Group>
    
                <Button variant="primary" type="submit">Soumettre</Button>
            </Form>
        </Container>
    );
    
}

export default EditClaims;
