import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../axiosInstance'; 
import { Container, Row, Col, Table, Button, Modal, Form, Alert } from "react-bootstrap";
import { useDropzone } from 'react-dropzone';
import DocumentFil from '../../components/DocumentFil/';

function ListFile() {
  const limit = 200;
  const page = 1;
  const { expertiseId } = useParams();
  const [files, setFiles] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showImageModal, setShowImageModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [editFile, setEditFile] = useState({
    id: '',
    title: '',
    description: '',
    category: '',
    rotation: '0',
    share: true,
    group: 99
  });
  const [showEditTitleModal, setShowEditTitleModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [uploadFiles, setUploadFiles] = useState([]);
  const [uploadStatuses, setUploadStatuses] = useState([]);  
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [showDocumentFil, setShowDocumentFil] = useState(false); // État pour contrôler la visibilité de DocumentFil

  const toggleDocumentFil = () => {
    setShowDocumentFil(!showDocumentFil); // Bascule entre afficher et masquer
  };

  const promptDeleteFile = (file) => {
    setFileToDelete(file);
    setShowDeleteModal(true);
  };

  const confirmDelete = () => {
    if (fileToDelete) {
      handleDeleteFile(fileToDelete.id);
      setShowDeleteModal(false);
      setFileToDelete(null); // Réinitialiser après suppression
    }
  };

  const onDrop = (acceptedFiles) => {
    console.log("Fichiers déposés :", acceptedFiles);
    setUploadFiles(acceptedFiles);
  };
  
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const fetchFiles = () => {
    const url = `/files?expertise_id=${expertiseId}&limit=${limit}&page=${page}`;
    console.log("Fetching files with URL:", url); // Debug: Log the full URL
  
    axiosInstance.get(url)
      .then(response => {
        const data = response.data;
        console.log("Total files received:", data.results ? data.results.length : data.length); // Debug: Log number of files received
  
        if (Array.isArray(data.results)) {
          setFiles(data.results);
        } else if (Array.isArray(data)) {
          setFiles(data); // Fallback if the API returns an array directly
        } else {
          console.error('Unexpected response structure:', data);
          setFiles([]);
        }
      })
      .catch(error => {
        console.error("Error while fetching files:", error.response ? error.response.data : error.message);
      });
  };

  useEffect(() => {
    fetchFiles();
  }, [expertiseId, page]);

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setShowImageModal(true);
  };
  
  const handleShowModal = (file) => {
    setEditFile({
      id: file.id || '',
      title: file.title || '',
      description: file.description || '',
      category: file.category || '',
      rotation: file.rotation || '0',
      share: file.share || false,
      group: file.group || 99 // Assuming 'group' is a numeric field; adjust the default as needed
    });
    setShowModal(true);
  };

  const handleShowEditTitleModal = (file) => {
    setSelectedFile(file); // Stocker les informations du fichier sélectionné
    setShowEditTitleModal(true); // Ouvrir le modal
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setEditFile(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmitEdit = (e) => {
    e.preventDefault();
  
    // Création d'un objet FormData
    const formData = new FormData();
    
    // Ajout des autres propriétés de editFile à formData
    formData.append('title', editFile.title);
    formData.append('description', editFile.description);
    formData.append('category', editFile.category);
    formData.append('rotation', editFile.rotation);
    formData.append('group', editFile.group);
    formData.append('share', editFile.share);

    // Mise à jour de la configuration pour supporter multipart/form-data
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
  
    axiosInstance.put(`/files/${editFile.id}/`, formData)
      .then(response => {
        // Logique de succès
        const index = files.findIndex(file => file.id === editFile.id);
        const updatedFiles = [...files];
        updatedFiles[index] = response.data;
        setFiles(updatedFiles);
        setShowModal(false);
      })
      .catch(error => {
        console.error('Erreur lors de la mise à jour du fichier', error);
        // Logique d'erreur
      });
  };

  const handleEditTitleSubmit = (e) => {
    e.preventDefault();
    if (selectedFile && selectedFile.id) {
      const formData = new FormData();
      formData.append('title', selectedFile.title);
      // Ajoutez d'autres champs au besoin, ou ajustez selon votre API
      
      axiosInstance.put(`/files/${selectedFile.id}/`, formData)
        .then((response) => {
          const index = files.findIndex(file => file.id === selectedFile.id);
          const updatedFiles = [...files];
          updatedFiles[index] = response.data;
          setFiles(updatedFiles);
          setShowEditTitleModal(false); // Fermer le modal
          // Vous pouvez également rafraîchir la liste des fichiers ici
        })
        .catch((error) => {
          console.error('Erreur lors de la mise à jour du titre', error);
          // Traiter les erreurs, éventuellement afficher un message d'erreur
        });
    }
  };

  const handleFileUpload = () => {
    console.log('uploadfile2: ', uploadFiles);
    uploadFiles.forEach(file => {
        const formData = new FormData(); // Déplacé à l'intérieur de la boucle
        const fileId = file.name; // Utilisez le nom du fichier comme identifiant unique
        formData.append('files', file, file.name);

        setUploadStatuses(prev => ({
            ...prev,
            [fileId]: { progress: 0, status: 'En cours' }
        }));

        axiosInstance.post(`/files/${expertiseId}/ajout_document/`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: progressEvent => {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setUploadStatuses(prev => ({
                    ...prev,
                    [fileId]: { ...prev[fileId], progress: percentCompleted }
                }));
            },
        })
        .then(response => {
            setUploadStatuses(prev => ({
                ...prev,
                [fileId]: { ...prev[fileId], status: 'Réussi' }
            }));
            fetchFiles(); // Rafraîchir la liste des fichiers après le téléchargement
        })
        .catch(error => {
            console.error('Erreur de téléchargement:', error);
            setUploadStatuses(prev => ({
                ...prev,
                [fileId]: { ...prev[fileId], status: 'Échoué' }
            }));
        });
    });
  };

  const handleDeleteFile = (fileId) => {
    axiosInstance.delete(`/files/${fileId}`)
      .then(() => {
        // Filtrez le fichier supprimé de l'état `files`
        const updatedFiles = files.filter(file => file.id !== fileId);
        setFiles(updatedFiles);
        // Optionnellement, affichez un message de succès
      })
      .catch(error => {
        console.error('Erreur lors de la suppression du fichier:', error);
        // Optionnellement, gérez l'erreur (par exemple, affichez un message d'erreur)
      });
  };

  const downloadFile = async (fileId, fileTitle, fileExtention) => {
    try {
      // Request the file data from the backend
      const response = await axiosInstance.get(`/pdf/${fileId}/`, {
        responseType: 'blob',  // Important for downloading files
      });
  
      // Create a URL from the Blob to be used for the download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      
      // Use fileTitle as the filename
      const extension = fileExtention.split('.').pop();
      const filename = `${fileTitle}.${extension}`;

      // Create an anchor (<a>) element to programmatically click for download
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();  // Trigger the download
      document.body.removeChild(link);  // Clean up
    } catch (error) {
      console.error('Error downloading file:', error);
      // Optionally show an error message to the user
    }
  };

  const onDocumentGenerated = () => {
    setSuccessMessage('Document généré avec succès!');
    fetchFiles();
    setTimeout(() => {
      setSuccessMessage('');
    }, 3000);
  };

  // Filtering files based on whether they are 'file' (images) or 'fichier' (non-images)
  const imageFiles = files.filter(file => file.file);
  const otherFiles = files.filter(file => file.fichier);

  return (
    <Container fluid>
      <Row>
        <Button onClick={() => setShowUploadModal(true)}>Nouveaux Documents</Button>
      </Row>
      <br />
      <Row>
        {successMessage && (
          <Alert variant="success">
            {successMessage}
          </Alert>
        )}
      </Row>
      <Row>
        <Col>
          {/* Bouton pour basculer l'affichage de DocumentFil */}
          <Button variant="secondary" onClick={toggleDocumentFil}>
            {showDocumentFil ? 'Masquer les templates de document' : 'Afficher les templates de document'}
          </Button>
        </Col>
      </Row>

      {/* Rendu conditionnel de DocumentFil */}
      {showDocumentFil && (
        <Row>
          <DocumentFil expertiseId={expertiseId} onSuccess={onDocumentGenerated} />
        </Row>
      )}
      <Row>
        <h2 className="expertise-title">Galerie d'Images</h2>
      </Row>
      <Row xs={1} md={2} lg={4} className="g-4">
        {imageFiles.map(file => (
          <Col key={file.id}>
            <Table>
              <tbody>
                <tr>
                  <td>
                    <img
                      src={file.file}
                      alt={file.title}
                      style={{ width: '100%', height: 'auto' }}
                      onClick={() => handleImageClick(file.file)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    Ordre: {file.group}
                  </td>
                </tr>
              </tbody>
            </Table>
            <Button variant="primary" onClick={() => handleShowModal(file)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5L13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5H5h.5v.5v.5v.5h.293l6.5-6.5zm-9.761 5.175l-.106.106-1.528 3.821 3.821-1.528.106-.106L3.707 10 2.032 10.675zm-.032 3.282l-.394.986.986-.394 2.598-2.598-.978-.978-2.212 2.984z"/>
              </svg>
            </Button>
            <Button variant="danger" onClick={() => promptDeleteFile(file)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" viewBox="0 0 16 16">
                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
              </svg>
            </Button>
          </Col>
        ))}
      </Row>
      <Row className="mt-4">
        <Col>
          <h2 className="expertise-title">Autres Fichiers</h2>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Titre</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {otherFiles.map(file => (
                <tr key={file.id}>
                  <td>{file.title}</td>
                  <td>
                    <Button variant="primary" onClick={() => handleShowEditTitleModal(file)}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5L13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5H5h.5v.5v.5v.5h.293l6.5-6.5zm-9.761 5.175l-.106.106-1.528 3.821 3.821-1.528.106-.106L3.707 10 2.032 10.675zm-.032 3.282l-.394.986.986-.394 2.598-2.598-.978-.978-2.212 2.984z"/>
                      </svg>
                    </Button>
                    {' '} {/* Espace entre les boutons */}
                    <a href="#" onClick={() => downloadFile(file.id, file.title, file.fichier)}>
                      <Button variant="success" className="ml-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                          <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.12 12.5 8 12.5s-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.173 8z"/>
                          <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                        </svg>
                      </Button>
                    </a>
                    {' '}
                    <Button variant="danger" onClick={() => promptDeleteFile(file)}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" viewBox="0 0 16 16">
                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                        <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                      </svg>
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
      <Modal show={showImageModal} onHide={() => setShowImageModal(false)} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={selectedImage} alt="Selected" style={{ width: '100%' }} />
        </Modal.Body>
      </Modal>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Éditer une image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmitEdit}>
            <Form.Group className="mb-3">
              <Form.Label>Titre</Form.Label>
              <Form.Control
                type="text"
                name="title"
                value={editFile.title}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                name="description"
                value={editFile.description}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Catégorie</Form.Label>
              <Form.Select name="category" value={editFile.category} onChange={handleChange}>
                {/* Itérer sur CATEGORY_CHOICES pour créer des options de sélection */}
                {[
                  ['facture', 'facture'],
                  ['devis', 'devis'],
                  ['photo', 'photo'],
                  // Ajoutez d'autres catégories ici
                ].map(([value, label]) => (
                  <option key={value} value={value}>{label}</option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Rotation</Form.Label>
              <Form.Select name="rotation" value={editFile.rotation} onChange={handleChange}>
                {/* Itérer sur ROTATION pour créer des options de sélection */}
                {[
                  [0, "0"],
                  [90, "90"],
                  [180, "180"],
                  [270, "270"],
                ].map(([value, label]) => (
                  <option key={value} value={value}>{label}</option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check 
                type="checkbox" 
                label="Partage" 
                name="share" 
                checked={editFile.share}
                onChange={handleChange} />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Ordre</Form.Label>
              <Form.Control
                type="number"
                name="group"
                value={editFile.group || ''}
                onChange={handleChange}
              />
            </Form.Group>
            <Button variant="secondary" onClick={() => setShowModal(false)} className="me-2">
              Annuler
            </Button>
            <Button variant="primary" type="submit">
              Sauvegarder
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal show={showEditTitleModal} onHide={() => setShowEditTitleModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Modifier le titre</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleEditTitleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Titre</Form.Label>
              <Form.Control
                type="text"
                value={selectedFile ? selectedFile.title : ''}
                onChange={(e) => setSelectedFile({ ...selectedFile, title: e.target.value })}
              />
            </Form.Group>
            <Button variant="secondary" onClick={() => setShowEditTitleModal(false)} className="me-2">
              Annuler
            </Button>
            <Button variant="primary" type="submit">
              Sauvegarder
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal show={showUploadModal} onHide={() => setShowUploadModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Télécharger des fichiers</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div 
            {...getRootProps()} 
            style={{
              border: '2px dashed #007bff', 
              padding: '20px', 
              borderRadius: '5px', 
              textAlign: 'center', 
              cursor: 'pointer'
            }} 
          >
            <input {...getInputProps()} />
            <p>Glisser-déposer des fichiers ici, ou cliquez pour sélectionner des fichiers</p>
          </div>
          {Object.keys(uploadStatuses).map(fileId => (
            <div key={fileId}>
              <strong>{fileId}</strong>: {uploadStatuses[fileId].status} - {uploadStatuses[fileId].progress}%
            </div>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowUploadModal(false)}>Fermer</Button>
          <Button variant="primary" onClick={handleFileUpload}>Télécharger</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmer la suppression</Modal.Title>
        </Modal.Header>
        <Modal.Body>Êtes-vous sûr de vouloir supprimer ce fichier ? Cette action est irréversible.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Annuler
          </Button>
          <Button variant="danger" onClick={confirmDelete}>
            Supprimer
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default ListFile;
