import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../axiosInstance'; 
import { Container, Row, Col, Table, Button, Modal, Form, InputGroup } from 'react-bootstrap';
import { Autocomplete } from '@mui/material';
import TextField from '@mui/material/TextField';

function ListCosting() {
  const { expertiseId } = useParams();
  const [costings, setCostings] = useState(null);
  //list des postes
  const [postCostings, setPostCostings] = useState([]);
  // Création d'un post
  const [showAddModal, setShowAddModal] = useState(false);
  //modification de post
  const [showEditModal, setShowEditModal] = useState(false);

  const [showAllCostingsModal, setShowAllCostingsModal] = useState(false);

  // Définitions à placer au début de votre fichier de composant React
  const TVA_CHOICES = [
    { value: 0, label: 'pas de tva' },
    { value: 3, label: '3 %' },
    { value: 6, label: '6 %' },
    { value: 16, label: '16 %' },
    { value: 17, label: '17 %' },
    { value: 20, label: '20 %' },
    { value: 21, label: '21 %' },
  ];

  const ORIGIN_CHOICES = [
    { value: '1', label: 'Bâtiment' },
    { value: '2', label: 'Pertes indirectes' },
    { value: '3', label: 'Perte financière' },
    { value: '4', label: 'Frais de gestion' },
    { value: '5', label: 'Frais de logement provisoire' },
    { value: '6', label: 'Frais de chômage immobilier' },
    { value: '7', label: 'Frais d\'expertise' },
    { value: '8', label: 'Frais administratifs' },
    { value: '9', label: 'Autres frais' },
  ];

  const UNIT_CHOICES = [
    { value: 'minute', label: 'minute' },
    { value: 'heure', label: 'heure' },
    { value: 'm', label: 'm' },
    { value: 'm2', label: 'm2' },
    { value: 'm3', label: 'm3' },
    { value: '%', label: '%' },
    { value: 'unit', label: 'unité' },
    { value: 'forfait', label: 'forfait' },
    { value: 'km', label: 'km' },
  ];

  const [addFormData, setAddFormData] = useState({
      title: '',
      comment: '',
      reclamer: 0.00,
      accorder: 0.00,
      recours: 0.00,
      total: 0.00,
      total_min: 0.00,
      total_max: 0.00,
      tva: 17, // valeur par défaut basée sur votre modèle
      origin: '1', // valeur par défaut
  });
  const [editFormData, setEditFormData] = useState({
    id: null,
    title: '',
    comment: '',
    reclamer: '',
    accorder: '',
    recours: '',
    total:'',
    total_min:'',
    total_max:'',
    tva: '',
    origin: '',
  });
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedPostIdForDelete, setSelectedPostIdForDelete] = useState(null);
  const [detailCostings, setDetailCostings] = useState({});
  const [showDetails, setShowDetails] = useState({});
  const [showEditDetailModal, setShowEditDetailModal] = useState(false);
  const [editDetailData, setEditDetailData] = useState({});
  const [showDeleteDetailModal, setShowDeleteDetailModal] = useState(false);
  const [detailIdToDelete, setDetailIdToDelete] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  
  
  const handleEditDetailClick = (detail) => {
    setEditDetailData(detail); // Pré-remplir les données du formulaire avec le détail à modifier
    setShowEditDetailModal(true); // Afficher le modal de modification
  };

  const handleOpenDeleteDetailModal = (detailId) => {
    setDetailIdToDelete(detailId);
    setShowDeleteDetailModal(true);
  };

  const handleUnitValueChange = (e) => {
    const updatedUnitValue = e.target.value;
    const updatedTotal = updatedUnitValue * newDetailData.quantity;
    setNewDetailData({...newDetailData, unitValue: updatedUnitValue, total: updatedTotal});
  };

  const handleQuantityChange = (e) => {
      const updatedQuantity = e.target.value;
      const updatedTotal = newDetailData.unitValue * updatedQuantity;
      setNewDetailData({...newDetailData, quantity: updatedQuantity, total: updatedTotal});
  };

  const handleToggleDetails = async (postId) => {
    // Si les détails sont déjà chargés et vous souhaitez les cacher, simplement inverser l'état de visibilité
    if (showDetails[postId]) {
      setShowDetails(prevDetails => ({
        ...prevDetails,
        [postId]: !prevDetails[postId]
      }));
    } else {
      // Si les détails ne sont pas encore chargés, les charger depuis le serveur
      try {
        // Affiche un état de chargement ou une indication visuelle si nécessaire
        // Charger les détails depuis le serveur
        await fetchDetailCostings(postId);
  
        // Après le chargement, ouvrir les détails
        setShowDetails(prevDetails => ({
          ...prevDetails,
          [postId]: true
        }));
      } catch (error) {
        console.error("Erreur lors de la récupération des détails de coût pour le poste de coût ID :", postId, error);
        // Gérer l'erreur ici, par exemple en affichant un message à l'utilisateur
      }
    }
  };
  useEffect(() => {
    let active = true;
  
    if (inputValue === '') {
      setOptions([]);
      return undefined;
    }
  
    (async () => {
      const response = await axiosInstance.get(`/pricinglist?query=${inputValue}`);
      const titles = response.data; // Supposons que cela retourne un tableau
      
      if (active) {
        setOptions(titles.results || titles);
      }
    })();
  
    return () => {
      active = false;
    };
  }, [inputValue]);
  

  const handleEditClick = (postCosting) => {
    setEditFormData({
      id: postCosting.id,
      title: postCosting.title,
      comment: postCosting.comment,
      reclamer: postCosting.reclamer.toString(),
      accorder: postCosting.accorder.toString(),
      recours: postCosting.recours.toString(),
      tva: postCosting.tva.toString(),
      origin: postCosting.origin,
    });
    setShowEditModal(true);
  };
  
  const handleShowDeleteModal = (postId) => {
    setSelectedPostIdForDelete(postId);
    setShowDeleteModal(true);
  };
  const [showAddDetailModal, setShowAddDetailModal] = useState(false);
  const [newDetailData, setNewDetailData] = useState({
      title: "",
      unitValue: 0,
      unit: "m2",
      quantity: 0,
      total: 0,
  });

  const [currentPostId, setCurrentPostId] = useState(null);



  const fetchCostings = async () => {
    try {
      const response = await axiosInstance.get(`/totalcostings/${expertiseId}/`);
      setCostings(response.data);
    } catch (error) {
      console.error("Erreur lors de la récupération des coûts :", error);
      // Gérer l'erreur ici, par exemple, en affichant un message
    }
  };
  const fetchPostCostings = async () => {
    try {
      const response = await axiosInstance.get(`/postcostings/?expertise_id=${expertiseId}`);
      const data = response.data;
      setPostCostings(data.results || data)
    } catch (error) {
      console.error("Erreur lors de la récupération des postes de coût :", error);
    }
  };

  const fetchDetailCostings = async (postCostingId) => {
    try {
        // Remplacez `/detailcostings/` par l'URL correcte de votre API pour récupérer les détails des coûts
        const response = await axiosInstance.get(`/detailcostings/?postCostingId=${postCostingId}`);
        const data = response.data;
        // Mise à jour de l'état avec les détails de coûts récupérés, en les associant à l'ID du poste de coût spécifique
        setDetailCostings(prevDetails => ({
            ...prevDetails,
            [postCostingId]: data.results || data
        }));
        fetchPostCostings();
    } catch (error) {
        console.error("Erreur lors de la récupération des détails de coût pour le poste de coût ID :", postCostingId, error);
    }
  };



  useEffect(() => {
    fetchCostings();
    fetchPostCostings();
  }, [expertiseId]);

  // Afficher un message de chargement si les données ne sont pas encore chargées
  if (!costings) {
    return <Container>Chargement des coûts...</Container>;
  }

  // Création d'un post
  const handleAddFormSubmit = async (e) => {
    e.preventDefault();
    const formattedData = {
        ...addFormData,
        totalCosting: costings.id // Assurez-vous que cette ID est correctement définie
    };
    try {
        await axiosInstance.post(`/postcostings/`, formattedData);
        setShowAddModal(false); // Fermer le modal après la soumission
        setAddFormData({ // Réinitialisation du formulaire à ses valeurs par défaut
          title: '',
          comment: '',
          reclamer: 0.00,
          accorder: 0.00,
          recours: 0.00,
          total: 0.00,
          total_min: 0.00,
          total_max: 0.00,
          tva: 17, // valeur par défaut basée sur votre modèle
          origin: '1', // Remettre la valeur par défaut ou la première valeur des ORIGIN_CHOICES
        });
        fetchCostings();
        fetchPostCostings();
    } catch (error) {
        console.error("Erreur lors de l'ajout du PostCosting :", error);
        // Gérer l'erreur ici, par exemple, en affichant un message d'erreur dans l'UI
    }
};
  // edit d'un poste
  const handleEditFormSubmit = async (e) => {
    e.preventDefault();
  
    const formattedData = {
      ...editFormData,
      // Assurez-vous que toutes les données numériques sont correctement formatées.
      reclamer: parseFloat(editFormData.reclamer),
      accorder: parseFloat(editFormData.accorder),
      recours: parseFloat(editFormData.recours),
      // Ajoutez d'autres transformations de données ici si nécessaire.
    };
  
    try {
      // Remplacez `postcostings` par le chemin correct de votre API.
      const response = await axiosInstance.put(`/postcostings/${editFormData.id}/`, formattedData);
      
      if (response.status === 200 || response.status === 201) {
        // Fermer le modal de modification
        setShowEditModal(false);
        
        // Mettre à jour l'état local pour refléter les modifications
        // Cela peut varier en fonction de la structure de vos données
        setPostCostings(prevPostCostings => prevPostCostings.map(post => 
          post.id === editFormData.id ? { ...post, ...formattedData } : post
        ));
  
        // Optionnel: Rafraîchir les données à partir de l'API si vous ne voulez pas les mettre à jour localement
        fetchPostCostings();
        fetchCostings();
      }
    } catch (error) {
      console.error("Erreur lors de la mise à jour du PostCosting :", error);
      // Gérer l'erreur ici, par exemple, en affichant un message d'erreur dans l'UI
    }
  };
  
 
  const handleDelete = async () => {
    if (selectedPostIdForDelete) {
      try {
        await axiosInstance.delete(`/postcostings/${selectedPostIdForDelete}/`);
        setPostCostings(prevPostCostings => prevPostCostings.filter(post => post.id !== selectedPostIdForDelete));
        setShowDeleteModal(false); // Fermer le modal après la suppression réussie
        fetchCostings();
      } catch (error) {
        console.error("Erreur lors de la suppression :", error);
        // Gérer l'erreur ici, par exemple, en affichant un message d'erreur
      }
    }
  };

const handleAddDetailSubmit = async (e) => {
  e.preventDefault();

  const detailToSave = {
      ...newDetailData,
      postCosting: currentPostId,
  };

  try {
      const response = await axiosInstance.post(`/detailcostings/`, detailToSave);

      if (response.status === 201) {
          setShowAddDetailModal(false);
          setNewDetailData({
              title: '',
              unitValue: 0,
              unit: 'm2',
              quantity: 0,
              total: 0,
          });

          fetchDetailCostings(currentPostId);
      }
  } catch (error) {
      console.error("Erreur lors de l'ajout d'un détail :", error.response.data);
  }
  };

  const handleEditDetailSubmit = async () => {
    console.log("Enregistrement des modifications", editDetailData);
    try {
      const response = await axiosInstance.put(`/detailcostings/${editDetailData.id}/`, editDetailData);
      if (response.status === 200) {
        // Mettre à jour l'affichage ou rafraîchir les données
        setShowEditDetailModal(false); // Fermer le modal
        fetchDetailCostings(editDetailData.postCosting);
      }
    } catch (error) {
      console.error("Erreur lors de la modification d'un détail :", error);
      // Gérer l'erreur ici
    }
  };
  
  const handleDeleteDetailConfirmed = async () => {
    if (!detailIdToDelete) return;
  
    try {
      const response = await axiosInstance.delete(`/detailcostings/${detailIdToDelete}/`);
      if (response.status === 204) {
        // Suppression réussie, rafraîchir la liste des détails
        const updatedDetails = { ...detailCostings };
        Object.keys(updatedDetails).forEach(postCostingId => {
          updatedDetails[postCostingId] = updatedDetails[postCostingId].filter(detail => detail.id !== detailIdToDelete);
        });
        setDetailCostings(updatedDetails);
        setShowDeleteDetailModal(false); // Fermer le modal après la suppression
      }
    } catch (error) {
      console.error("Erreur lors de la suppression d'un détail :", error);
      // Gérer l'erreur ici
    }
  };
  
  return (
    <Container fluid>
      <h2 className="expertise-title">Détails des Coûts</h2>
      <Button onClick={() => setShowAddModal(true)}>Ajouter un poste</Button>
      <Button variant="info" onClick={() => setShowAllCostingsModal(true)}>Afficher tous les Coûts</Button>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Total Réclamé HTVA</th>
            <th>Total Retenu HTVA</th>
            <th>Total Recours HTVA</th>
            <th>Total Réclamé TTC</th>
            <th>Total Retenu TTC</th>
            <th>Total Recours TTC</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{costings.totalReclamerHtva}</td>
            <td>{costings.totalAccorderHtva}</td>
            <td>{costings.totalRecoursHtva}</td>
            <td>{costings.totalReclamerTtc}</td>
            <td>{costings.totalAccorderTtc}</td>
            <td>{costings.totalRecoursTtc}</td>
          </tr>
        </tbody>
      </Table>
      <h2>Liste des Postes de Coût</h2>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Titre</th>
            <th>Réclamé</th>
            <th>Retenu</th>
            <th>Recours</th>
            <th>TVA</th>
            <th>Origine</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {postCostings.map((post, index) => (
            <React.Fragment key={`postcostings_${index}`}>
              <tr>
                <td>{post.title}</td>
                <td>{Number(post.reclamer).toFixed(2)}</td>
                <td>{Number(post.accorder).toFixed(2)}</td>
                <td>{Number(post.recours).toFixed(2)}</td>
                <td>{`${post.tva}%`}</td>
                <td>{ORIGIN_CHOICES.find(option => option.value === post.origin)?.label || "Label inconnu"}</td>
                <td>
                  <Button variant="primary" size="sm" onClick={() => handleEditClick(post)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5L13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5H5h.5v.5v.5v.5h.293l6.5-6.5zm-9.761 5.175l-.106.106-1.528 3.821 3.821-1.528.106-.106L3.707 10 2.032 10.675zm-.032 3.282l-.394.986.986-.394 2.598-2.598-.978-.978-2.212 2.984z"/>
                    </svg>
                  </Button>
                  <Button variant="danger" size="sm" onClick={() => handleShowDeleteModal(post.id)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" viewBox="0 0 16 16">
                      <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                      <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                    </svg>
                  </Button>
                  <Button variant="primary" size="sm" onClick={() => handleToggleDetails(post.id)}>
                    {showDetails[post.id] ? 
                      (<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M3.5 8a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 0 1h-8a.5.5 0 0 1-.5-.5z"/>
                      </svg>) : 
                      (<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M8 3.5a.5.5 0 0 1 .5.5v4h4a.5.5 0 0 1 0 1h-4v4a.5.5 0 0 1-1 0v-4h-4a.5.5 0 0 1 0-1h4v-4a.5.5 0 0 1 .5-.5z"/>
                      </svg>)
                    }
                  </Button>

                </td>
              </tr>
              {showDetails[post.id] && detailCostings[post.id] && detailCostings[post.id].map((detail, detailIndex) => (
                <tr key={`detail-${detailIndex}`}>
                    <td>
                      <Button variant="warning" size="sm" onClick={() => handleEditDetailClick(detail)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                          <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5L13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5H5h.5v.5v.5v.5h.293l6.5-6.5zm-9.761 5.175l-.106.106-1.528 3.821 3.821-1.528.106-.106L3.707 10 2.032 10.675zm-.032 3.282l-.394.986.986-.394 2.598-2.598-.978-.978-2.212 2.984z"/>
                        </svg>
                      </Button>
                      <Button variant="danger" size="sm" onClick={() => handleOpenDeleteDetailModal(detail.id)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" viewBox="0 0 16 16">
                          <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                          <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                        </svg>
                      </Button>
                    </td>
                    <td colSpan="2">
                        Détail: {detail.title}
                    </td>
                    <td >
                        {detail.unitValue} €
                    </td>
                    <td >
                        Unité: {detail.unit}
                    </td>
                    <td >
                        Quantité: {detail.quantity}
                    </td>
                    <td >
                        Total: {detail.total} €
                    </td>
                </tr>
              ))}
              {showDetails[post.id] && (
                <tr>
                  <td></td>
                  <td colSpan="7">
                  <Button variant="success" size="sm" onClick={() => {
                      setCurrentPostId(post.id);
                      setShowAddDetailModal(true);
                  }}>
                    Ajouter un Détail
                  </Button>
                  </td>
                </tr>
              )}
              {post.total > 0 && (
                <tr>
                  <td></td>
                  <td>Total chiffrage</td>
                  <td colSpan="4"></td>
                  <td>{Number(post.total).toFixed(2)}</td>
                </tr>
              )}

            </React.Fragment>
          ))}
        </tbody>

      </Table>
      <Modal  show={showAddModal} onHide={() => setShowAddModal(false)} backdrop="static" size="xl">
        <Form onSubmit={handleAddFormSubmit}>
            <Modal.Header closeButton>
                <Modal.Title>Ajouter un nouveau poste</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group>
                  <Form.Label>Titre</Form.Label>
                  <Form.Control
                      type="text"
                      placeholder="Titre"
                      value={addFormData.title}
                      onChange={(e) => setAddFormData({ ...addFormData, title: e.target.value })}
                  />
              </Form.Group>
              <Form.Group>
                  <Form.Label>Commentaire</Form.Label>
                  <Form.Control
                      as="textarea"
                      rows={7}
                      placeholder="Commentaire"
                      value={addFormData.comment}
                      onChange={(e) => setAddFormData({ ...addFormData, comment: e.target.value })}
                  />
              </Form.Group>
              <Form.Group>
                  <Form.Label>Réclamé</Form.Label>
                  <Form.Control
                      type="number"
                      placeholder="Montant Réclamé"
                      value={addFormData.reclamer}
                      onChange={(e) => setAddFormData({ ...addFormData, reclamer: e.target.value })}
                  />
              </Form.Group>

              <Form.Group>
                  <Form.Label>Retenu</Form.Label>
                  <Form.Control
                      type="number"
                      placeholder="Montant Retenu"
                      value={addFormData.accorder}
                      onChange={(e) => setAddFormData({ ...addFormData, accorder: e.target.value })}
                  />
              </Form.Group>

              <Form.Group>
                  <Form.Label>Recours</Form.Label>
                  <Form.Control
                      type="number"
                      placeholder="Montant Recours"
                      value={addFormData.recours}
                      onChange={(e) => setAddFormData({ ...addFormData, recours: e.target.value })}
                  />
              </Form.Group>

              <Form.Group>
                  <Form.Label>TVA</Form.Label>
                  <Form.Control
                      as="select"
                      value={addFormData.tva}
                      onChange={(e) => setAddFormData({ ...addFormData, tva: e.target.value })}
                  >
                      {TVA_CHOICES.map((option) => (
                          <option key={`tva_${option.value}`} value={option.value}>
                              {option.label}
                          </option>
                      ))}
                  </Form.Control>
              </Form.Group>

              <Form.Group>
                  <Form.Label>Origine</Form.Label>
                  <Form.Control
                      as="select"
                      value={addFormData.origin}
                      onChange={(e) => setAddFormData({ ...addFormData, origin: e.target.value })}
                  >
                      {ORIGIN_CHOICES.map((option) => (
                          <option key={`origine_${option.value}`} value={option.value}>
                              {option.label}
                          </option>
                      ))}
                  </Form.Control>
              </Form.Group>
          </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowAddModal(false)}>Fermer</Button>
                <Button variant="primary" type="submit">Sauvegarder</Button>
            </Modal.Footer>
        </Form>
      </Modal>
      <Modal  show={showEditModal} onHide={() => setShowEditModal(false)} backdrop="static" size="xl">
        <Form onSubmit={handleEditFormSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Modifier le poste</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <Form.Group>
                  <Form.Label>Titre</Form.Label>
                  <Form.Control
                      type="text"
                      placeholder="Titre"
                      value={editFormData.title}
                      onChange={(e) => setEditFormData({ ...editFormData, title: e.target.value })}
                  />
              </Form.Group>
              <Form.Group>
                  <Form.Label>Commentaire</Form.Label>
                  <Form.Control
                      as="textarea"
                      rows={7}
                      placeholder="Commentaire"
                      value={editFormData.comment}
                      onChange={(e) => setEditFormData({ ...editFormData, comment: e.target.value })}
                  />
              </Form.Group>

              <Form.Group>
                  <Form.Label>Réclamé</Form.Label>
                  <Form.Control
                      type="number"
                      placeholder="Montant Réclamé"
                      value={editFormData.reclamer}
                      onChange={(e) => setEditFormData({ ...editFormData, reclamer: e.target.value })}
                  />
              </Form.Group>

              <Form.Group>
                  <Form.Label>Retenu</Form.Label>
                  <Form.Control
                      type="number"
                      placeholder="Montant Retenu"
                      value={editFormData.accorder}
                      onChange={(e) => setEditFormData({ ...editFormData, accorder: e.target.value })}
                  />
              </Form.Group>

              <Form.Group>
                  <Form.Label>Recours</Form.Label>
                  <Form.Control
                      type="number"
                      placeholder="Montant Recours"
                      value={editFormData.recours}
                      onChange={(e) => setEditFormData({ ...editFormData, recours: e.target.value })}
                  />
              </Form.Group>

              <Form.Group>
                  <Form.Label>TVA</Form.Label>
                  <Form.Control
                      as="select"
                      value={editFormData.tva}
                      onChange={(e) => setEditFormData({ ...editFormData, tva: e.target.value })}
                  >
                      {TVA_CHOICES.map((option) => (
                          <option key={`tva_${option.value}`} value={option.value}>
                              {option.label}
                          </option>
                      ))}
                  </Form.Control>
              </Form.Group>

              <Form.Group>
                  <Form.Label>Origine</Form.Label>
                  <Form.Control
                      as="select"
                      value={editFormData.origin}
                      onChange={(e) => setEditFormData({ ...editFormData, origin: e.target.value })}
                  >
                      {ORIGIN_CHOICES.map((option) => (
                          <option key={`origine_${option.value}`} value={option.value}>
                              {option.label}
                          </option>
                      ))}
                  </Form.Control>
              </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowEditModal(false)}>Annuler</Button>
            <Button variant="primary" type="submit">Sauvegarder les modifications</Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <Modal  show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Effacer l'objet?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Etes-vous sur de vouloir effacer la ligne?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={() => handleDelete()}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal  show={showAddDetailModal} onHide={() => setShowAddDetailModal(false)} backdrop="static" size="xl">
        <Modal.Header closeButton>
            <Modal.Title>Ajouter un Nouveau Détail</Modal.Title>
        </Modal.Header>
          <Modal.Body>
            <Form>
            <Autocomplete
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              options={options}
              getOptionLabel={(option) => option.title || ''}
              onChange={(event, newValue) => {
                if (newValue) {
                  setNewDetailData(prevData => ({
                    ...prevData,
                    title: newValue.title,
                    unitValue: newValue.unityMax,
                    unit: newValue.unit,
                  }));
                }
              }}              
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => <TextField {...params} label="Choisir un titre" />}
            />
                <Form.Group controlId="detailTitle">
                    <Form.Label>Titre</Form.Label>
                    <Form.Control 
                        type="text" 
                        value={newDetailData.title} 
                        onChange={e => setNewDetailData({...newDetailData, title: e.target.value})}
                    />
                </Form.Group>
                <Form.Group controlId="detailUnitValue">
                    <Form.Label>Valeur unitaire</Form.Label>
                    <Form.Control 
                        type="number" 
                        value={newDetailData.unitValue} 
                        onChange={handleUnitValueChange}
                    />
                </Form.Group>
                <Form.Group controlId="detailQuantity">
                    <Form.Label>Quantité</Form.Label>
                    <Form.Control 
                        type="number" 
                        value={newDetailData.quantity} 
                        onChange={handleQuantityChange}
                    />
                </Form.Group>
                <Form.Group controlId="detailUnit">
                    <Form.Label>Unité</Form.Label>
                    <Form.Control 
                        as="select" 
                        value={newDetailData.unit} 
                        onChange={e => setNewDetailData({...newDetailData, unit: e.target.value})}
                    >
                        {UNIT_CHOICES.map(option => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </Form.Control>
                </Form.Group>
                <Form.Group controlId="detailTotal">
                    <Form.Label>Total</Form.Label>
                    <Form.Control 
                        type="number" 
                        value={newDetailData.total} 
                        readOnly
                    />
                </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowAddDetailModal(false)}>Fermer</Button>
            <Button variant="primary" onClick={(e) => handleAddDetailSubmit(e)}>Enregistrer</Button>
      </Modal.Footer>
    </Modal>
    <Modal  show={showEditDetailModal} onHide={() => setShowEditDetailModal(false)} backdrop="static" size="xl">
    <Modal.Header closeButton>
        <Modal.Title>Modifier le Détail</Modal.Title>
    </Modal.Header>
    <Modal.Body>
        <Form>
        <Autocomplete
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          options={options}
          getOptionLabel={(option) => option.title || ''}
          onChange={(event, newValue) => {
            if (newValue) {
              setEditDetailData(prevData => ({
                ...prevData,
                title: newValue.title,
                unitValue: newValue.unityMax,
                unit: newValue.unit,
              }));
            }
          }}          
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => <TextField {...params} label="Choisir un titre" />}
        />


            <Form.Group controlId="editDetailTitle">
                <Form.Label>Titre</Form.Label>
                <Form.Control 
                    type="text" 
                    value={editDetailData.title || ''} 
                    onChange={e => setEditDetailData({...editDetailData, title: e.target.value})}
                />
            </Form.Group>
            <Form.Group controlId="editDetailUnitValue">
                <Form.Label>Valeur unitaire</Form.Label>
                <Form.Control 
                    type="number" 
                    value={editDetailData.unitValue || 0} 
                    onChange={e => setEditDetailData({...editDetailData, unitValue: parseFloat(e.target.value)})}
                />
            </Form.Group>
            <Form.Group controlId="editDetailQuantity">
                <Form.Label>Quantité</Form.Label>
                <Form.Control 
                    type="number" 
                    value={editDetailData.quantity || 0} 
                    onChange={e => setEditDetailData({...editDetailData, quantity: parseFloat(e.target.value)})}
                />
            </Form.Group>
            <Form.Group controlId="editDetailUnit">
                <Form.Label>Unité</Form.Label>
                <Form.Control 
                    as="select" 
                    value={editDetailData.unit || ''} 
                    onChange={e => setEditDetailData({...editDetailData, unit: e.target.value})}
                >
                    {UNIT_CHOICES.map(option => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </Form.Control>
            </Form.Group>
            <Form.Group controlId="editDetailTotal">
                <Form.Label>Total</Form.Label>
                <Form.Control 
                    type="number" 
                    value={editDetailData.total || 0} 
                    readOnly
                />
            </Form.Group>
        </Form>
    </Modal.Body>
    <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowEditDetailModal(false)}>Fermer</Button>
        <Button variant="primary" onClick={handleEditDetailSubmit}>Enregistrer les modifications</Button>
    </Modal.Footer>
    </Modal>
    <Modal  show={showDeleteDetailModal} onHide={() => setShowDeleteDetailModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Confirmer la suppression</Modal.Title>
      </Modal.Header>
      <Modal.Body>Êtes-vous sûr de vouloir supprimer ce détail ? Cette action est irréversible.</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowDeleteDetailModal(false)}>Annuler</Button>
        <Button variant="danger" onClick={() => handleDeleteDetailConfirmed()}>Supprimer</Button>
      </Modal.Footer>
    </Modal>

    <Modal show={showAllCostingsModal} onHide={() => setShowAllCostingsModal(false)} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Tous les postes de coût</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Résumé des postes</h4>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Postes</th>
              <th>Réclamé HTVA</th>
              <th>Retenu HTVA</th>
              <th>Tva à appliquer</th>
              <th>Retenu TTC</th>
              <th>Accord client</th>
            </tr>
          </thead>
          <tbody>
            {postCostings.map((post, postindex) => (
              <tr key={postindex}>
                <td>{post.title}</td>
                <td className="text-right">{Number(post.reclamer).toFixed(2)}€</td>
                <td className="text-right">{Number(post.accorder).toFixed(2)}€</td>
                <td className="text-right">{`${post.tva}%`}</td>
                <td className="text-right">{(Number(post.accorder) * (1 + post.tva / 100)).toFixed(2)}€</td>
                <td className="text-right">Oui</td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td><strong>Total</strong></td>
              <td className="text-right">{Number(costings.totalReclamerHtva).toFixed(2)}€</td>
              <td className="text-right">{Number(costings.totalAccorderHtva).toFixed(2)}€</td>
              <td></td>
              <td className="text-right">{Number(costings.totalAccorderTtc).toFixed(2)}€</td>
              <td></td>
            </tr>
          </tfoot>
        </Table>

        <h4>Détail des postes</h4>
        {postCostings.map((post, index) => (
          <div key={index}>
            <h5>{post.title}</h5>
            <Table striped bordered hover className="mb-3">
              <thead>
                <tr>
                  <th>Réclamé</th>
                  <th>Retenu</th>
                  <th>Recours</th>
                  <th>TVA</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-right">{Number(post.reclamer).toFixed(2)}€</td>
                  <td className="text-right">{Number(post.accorder).toFixed(2)}€</td>
                  <td className="text-right">{Number(post.recours).toFixed(2)}€</td>
                  <td className="text-right">{`${post.tva}%`}</td>
                </tr>
              </tbody>
            </Table>
            <p>{post.comment}</p>

            {detailCostings[post.id] && detailCostings[post.id].length > 0 ? (
              <Table striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th>Détail du poste</th>
                    <th>Valeur Unitaire (€)</th>
                    <th>Unité</th>
                    <th>Quantité</th>
                    <th>Total (€)</th>
                  </tr>
                </thead>
                <tbody>
                  {detailCostings[post.id].map((detail, detailIndex) => (
                    <tr key={detailIndex}>
                      <td>{detail.title}</td>
                      <td className="text-right">{(parseFloat(detail.unitValue) || 0).toFixed(2)}€</td>
                      <td>{detail.unit}</td>
                      <td className="text-right">{(parseFloat(detail.quantity) || 0).toFixed(2)}</td>
                      <td className="text-right">{(parseFloat(detail.total) || 0).toFixed(2)}€</td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td>
                      <b>Total</b>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className="text-right">{Number(post.total).toFixed(2)}€</td>
                  </tr>
                </tfoot>
              </Table>
            ) : (
              <p className="text-muted">Aucun détail de coût disponible pour ce poste.</p>
            )}
          </div>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowAllCostingsModal(false)}>Fermer</Button>
      </Modal.Footer>
    </Modal>

    </Container>
  );
}

export default ListCosting;
