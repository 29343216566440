import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Container, Form, Button, Alert } from 'react-bootstrap';
import axiosInstance from '../../axiosInstance'; 

function EditContract() {
    const { expertiseId } = useParams(); 
    const [contract, setContract] = useState({
        id: null,
        title: '',
        reference: '',
        startDate: '',
        referenceCG: '',
        assureur_id: '',
    });
    const [assureurs, setAssureurs] = useState([]);
    const [generalConditions, setGeneralConditions] = useState([]);  // Nouvelle état pour les conditions générales
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');


    useEffect(() => {
        // Charger les détails du contrat spécifique
        axiosInstance.get(`/contract/${ expertiseId }/`)
            .then(response => {
                const data = response.data;
                setContract({
                    id: data.id,
                    title: data.title || '',
                    reference: data.reference || '',
                    startDate: data.startDate ? new Date(data.startDate).toISOString().split('T')[0] : '',
                    referenceCG: data.referenceCG || '',
                    assureur_id: data.assureur ? data.assureur.id : '',
                });
            })
            .catch(err => {
                console.error("Erreur lors du chargement du contrat", err);
                setError('Erreur lors du chargement du contrat');
            });

        // Charger la liste des assureurs pour le select
        axiosInstance.get(`/insurance/`)
            .then(response => {
                const data = response.data;
                if (Array.isArray(data.results)) { 
                    setAssureurs(data.results); 
                } else {
                    console.error('La réponse n\'est pas un tableau', data.results);
                    setAssureurs([]); 
                }
            })
            .catch(err => {
                console.error("Erreur lors du chargement des assureurs", err);
                setError('Erreur lors du chargement des assureurs');
            });
    }, [expertiseId]);

    // Charger les conditions générales en fonction de l'assureur sélectionné
    useEffect(() => {
        if (contract.assureur_id) {
            axiosInstance.get(`/general-conditions/?insurance=${contract.assureur_id}`)
                .then(response => {
                    const data = response.data;
                    if (Array.isArray(data.results)) {
                        setGeneralConditions(data.results); 
                    } else {
                        console.error('La réponse n\'est pas un tableau', data.results);
                        setGeneralConditions([]); 
                    }
                })
                .catch(err => {
                    console.error("Erreur lors du chargement des conditions générales", err);
                    setError('Erreur lors du chargement des conditions générales');
                });
        } else {
            axiosInstance.get(`/general-conditions/`)
                .then(response => {
                    const data = response.data;
                    if (Array.isArray(data.results)) {
                        setGeneralConditions(data.results); 
                    } else {
                        console.error('La réponse n\'est pas un tableau', data.results);
                        setGeneralConditions([]); 
                    }
                })
                .catch(err => {
                    console.error("Erreur lors du chargement des conditions générales", err);
                    setError('Erreur lors du chargement des conditions générales');
                });
        }
    }, [contract.assureur_id]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setContract(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleDownload = () => {
        const selectedCondition = generalConditions.find(condition => condition.name === contract.referenceCG);
        if (selectedCondition && selectedCondition.fichier) {
            window.open(selectedCondition.fichier, '_blank');
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formattedData = {
            ...contract,
            startDate: contract.startDate ? new Date(contract.startDate).toISOString().split('T')[0] : null,
            assureur_id: contract.assureur_id ? contract.assureur_id[0] : null,
        };
        if (contract.id) {
            try {
                const response = await axiosInstance.put(`/contract/${ expertiseId }/`, formattedData);
                // Afficher un message de succès
                setSuccessMessage('Les champs ont bien été modifiés.');
                // Réinitialiser l'alerte après un certain temps si nécessaire
                setTimeout(() => setSuccessMessage(''), 3000); // Masquer l'alerte après 3 secondes
            } catch (error) {
                console.error("Erreur lors de la modification d'un contrat", error);
                if (error.response && error.response.data) {
                    console.log(error.response.data);
                }
            }
        } else {
            console.error('ID du contrat non spécifié ou incorrect');
        }
    };
    

    return (
        <Container fluid>
            <h2 className="expertise-title">Modifier un contrat</h2>
            {error && <Alert variant="danger">{error}</Alert>}
            {successMessage && <Alert variant="success">{successMessage}</Alert>}
            <Form onSubmit={handleSubmit}>
                {/* Form fields */}
                {/* <Form.Group className="mb-3">
                    <Form.Label>Titre</Form.Label>
                    <Form.Control type="text" name="title" value={contract.title} onChange={handleInputChange} />
                </Form.Group> */}
                <Form.Group className="mb-3">
                    <Form.Label>Référence du contrat</Form.Label>
                    <Form.Control type="text" name="reference" value={contract.reference} onChange={handleInputChange} />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Date de souscription</Form.Label>
                    <Form.Control type="date" name="startDate" value={contract.startDate} onChange={handleInputChange} />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Assureur</Form.Label>
                    <Form.Control as="select" name="assureur_id" value={contract.assureur_id} onChange={handleInputChange}>
                        <option value="">Choisir un assureur</option>
                        {assureurs.map(assureur => (
                            <option key={assureur.id} value={assureur.id}>
                                {assureur.person.nameCompany}
                            </option>
                        ))}
                    </Form.Control>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Nom des conditions générales</Form.Label>
                    <Form.Control 
                        list="general-conditions-list" 
                        name="referenceCG" 
                        value={contract.referenceCG} 
                        onChange={handleInputChange} 
                        placeholder="Choisir ou entrer une condition générale" 
                        autoComplete="off"
                    />
                    <datalist id="general-conditions-list">
                        {generalConditions.map(condition => (
                            <option key={condition.id} value={condition.name} />
                        ))}
                    </datalist>
                </Form.Group>
                <p>
                    <Button 
                    variant="secondary" 
                    onClick={handleDownload} 
                    disabled={!contract.referenceCG || !generalConditions.some(condition => condition.name === contract.referenceCG && condition.fichier)}
                    >
                        Télécharger les Conditions Générales
                    </Button>
                </p>
                <p>
                    <Button variant="primary" type="submit" className="mt-3">Soumettre</Button>
                </p>
            </Form>
        </Container>
    );
    
}

export default EditContract;
