import React, { useState, useEffect } from 'react';
import { Button, Container, Table, Alert } from 'react-bootstrap';
import axiosInstance from '../../axiosInstance';

function DocumentFil({ expertiseId, onSuccess }) {
    const [files, setFiles] = useState([]);
    const [successMessage, setSuccessMessage] = useState('');

    useEffect(() => {
        fetchFiles();
    }, []);

    const fetchFiles = () => {
        axiosInstance.get(`/orgafiles/`)
            .then(response => {
                const data = response.data;
                setFiles(data.results || data);
            })
            .catch(error => console.error('Error fetching files:', error));
    };

    const generateDocument = (fileId) => {
        console.log('id', fileId)
        axiosInstance.post(`/organization_file_fill/${expertiseId}/${fileId}/`)
            .then(response => {
                console.log('Document generated successfully:', response.data);
                setSuccessMessage('Document généré avec succès!');
                fetchFiles(); // Refresh the file list after generating the document
                setTimeout(() => {
                    setSuccessMessage(''); // Clear the success message after 3 seconds
                }, 3000);
                if (onSuccess) {
                    onSuccess(); // Call the onSuccess callback to update the parent component
                }
            })
            .catch(error => console.error('Error generating document:', error));
    };

    return (
        <Container fluid>
            <h2>Template de documents</h2>
            {successMessage && (
                <Alert variant="success">
                    {successMessage}
                </Alert>
            )}
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Title</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {files.map(file => (
                        <tr key={file.id}>
                            <td>{file.title}</td>
                            <td>
                                <Button variant="primary" onClick={() => generateDocument(file.id)}>
                                    Générer un Document
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </Container>
    );
}

export default DocumentFil;
